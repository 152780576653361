import React from "react"
import Template from "../../templates/capabilities"
import { useStaticQuery, graphql } from "gatsby"
import { CAPABILITIES } from "../../config"

const Page = props => {
  return <Template {...props} options={CAPABILITIES["asset"]}></Template>
}

export default props => {
  const data = useStaticQuery(graphql`
    query {
      splashImage: file(relativePath: { eq: "banners/a-r.png" }) {
        childImageSharp {
          fluid(maxWidth: 1180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const splashImage = data.splashImage.childImageSharp.fluid
  const images = [splashImage]
  return <Page {...props} images={images} />
}
